import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageBanner from "../layouts/PageBanner";
import TeamMemberCard from "./TeamMemberCard";
import bg from "../assets/images/banner/bnr1.jpg"; // Adjust as needed

const teamMembers = [
  // Directors
  {
    image: require("../assets/images/team/team4.jpeg"),
    name: "Abdu. Bala",
    // phoneNumber: "08036063496",
    // email: "yakubu227@gmail.com",
    position: "Director Finance and Admin",
  },
  {
    image: require("../assets/images/team/team2.jpeg"),
    name: "Babangida Maigari",
    // phoneNumber: "07033418329",
    // email: "yakubu227@gmail.com",
    position: "Director Community Mobilisation",
  },

  // Heads of Departments (HODs)
  {
    image: require("../assets/images/team/team6.jpg"),
    name: "Abubakar I Muhammaad",
    // phoneNumber: "08031318384",
    // email: "babaumar273@gmail.com",
    position: "H.O.D HEALTH",
  },
  {
    image: require("../assets/images/team/team10.jpg"),
    name: "Baba Umar",
    // phoneNumber: "08031318384",
    // email: "babaumar273@gmail.com",
    position: "HOD Information/ICT",
  },
  {
    image: require("../assets/images/team/team9.jpg"),
    name: "Aminu Yakubu Nasarawa",
    // phoneNumber: "08031318384",
    // email: "babaumar273@gmail.com",
    position: "HOD H.E.N.S/Psycosocial",
  },
  {
    image: require("../assets/images/team/team11.jpg"),
    name: "Fatima Tata",
    // phoneNumber: "08031318384",
    // email: "babaumar273@gmail.com",
    position: "HOD Food and Nutrition",
  },
  {
    image: require("../assets/images/team/team12.jpg"),
    name: "Jamilu dan Azumi",
    // phoneNumber: "08031318384",
    // email: "babaumar273@gmail.com",
    position: "HOD Child Protection",
  },

  // Remaining members
  {
    image: require("../assets/images/team/team3.jpeg"),
    name: "Fatima B. Othman",
    // phoneNumber: "08036026171",
    // email: "tsamiya68@gmail.com",
    position: "Chief Store Officer",
  },
  {
    image: require("../assets/images/team/team5.jpeg"),
    name: "Adamu Nuhu",
    // phoneNumber: "08038135790",
    // email: "babaumar273@gmail.com",
    position: "Education Officer",
  },
  {
    image: require("../assets/images/team/team7.jpg"),
    name: "Shehu Magogo",
    // phoneNumber: "08031318384",
    // email: "babaumar273@gmail.com",
    position: "Staff Officer",
  },
  {
    image: require("../assets/images/team/team8.jpg"),
    name: "Isiyaku M Tukur",
    // phoneNumber: "08031318384",
    // email: "babaumar273@gmail.com",
    position: "Admin Officer",
  },
];

const Team = () => {
  return (
    <div className="page-content bg-white">
      <PageBanner
        maintitle="Our Team"
        pagetitle="Meet the Team"
        background={bg}
      />
      <section className="content-inner-1 bg-light">
        <Container>
          <Row className="justify-content-center">
            {teamMembers.map((member, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="d-flex">
                <TeamMemberCard {...member} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Team;
