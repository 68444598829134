import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import PropTypes from 'prop-types'; // Import PropTypes

const TeamMemberCard = ({ image, name, phoneNumber, email, position }) => {
  const handlePhoneClick = () => {
    window.open(`tel:${phoneNumber}`, "_self");
  };

  const handleEmailClick = () => {
    window.open(`mailto:${email}`, "_self");
  };

  return (
    <Card className="mb-4 shadow-sm" style={{ width: '100%' }}>
      <Card.Img variant="top" src={image} style={{ height: '200px', objectFit: 'cover' }} />
      <Card.Body className="text-center">
        <Card.Title className="text-uppercase">{name}</Card.Title>
        <Card.Text>
          <span>{position}</span>
        </Card.Text>
        <Row className="justify-content-center">
        {phoneNumber && (
              <Col xs="auto">
              <Button variant="link" onClick={handlePhoneClick}>
                <FaPhone style={{ color: '#007bff' }} /> 
              </Button>
            </Col>
          )}
        
          {email && (
            <Col xs="auto">
              <Button variant="link" onClick={handleEmailClick}>
                <FaEnvelope style={{ color: '#007bff' }} />
              </Button>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

// Add prop types validation
TeamMemberCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string,
  position: PropTypes.string.isRequired,
};

export default TeamMemberCard;
